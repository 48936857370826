import scrollLock from "../utils/scroll-lock";
// menu open
$( ".hamburger" ).click( function() {
	$( ".header" ).toggleClass( "menu-open" );
	$( "body" ).toggleClass( "is-nav-active" );

} );

if ( $( window ).scrollTop() > 1 ) {
	$( ".header" ).addClass( "fixed" );
}

// fixed header
$( window ).scroll( function() {
	if ( $( this ).scrollTop() > 1 ) {
		$( ".header" ).addClass( "fixed" );
	} else if ( $( this ).scrollTop() < 1 ) {
		$( ".header" ).removeClass( "fixed" );
	}
} );

window.addEventListener( "load", () => {
	var pageHash = location.hash;
	location.hash = "";
	if ( pageHash[1] !== undefined ) {
		var wpAdminBar = $( "#wpadminbar" ).length ? $( "#wpadminbar" ).outerHeight() : 0;
		setTimeout( function() {
			$( "html, body" ).animate(
				{scrollTop: $( pageHash ).offset().top - wpAdminBar }
				, 1200 );
			location.hash = pageHash;
		}, 400 );
	}
} );

$( ".menu-item a" ).on( "click", function() {
	if ( this.hash !== "" ) {
		var hash = this.hash;
		if ( $( hash ).length ) {
			if ( $( "body" ).hasClass( "is-nav-active" ) ) {

				scrollLock.disable();
		
				$( "body" ).removeClass( "is-nav-active" );
			
				$( ".header" ).removeClass( "menu-open" );
		
			}

			$( "html, body" ).animate( {
				scrollTop: $( hash ).offset().top
			}, 2000, function() {
				window.location.hash = hash;
			} );
			return false;
		}
	}
} );

// nav section
$( ".section" ).each( function( index ) {
	var liElement = $( "<li></li>" );
	liElement.on( "click", function() {
		var targetSection = $( ".section" ).eq( index );
		$( "html, body" ).animate( {
			scrollTop: targetSection.offset().top - 100 + "px"
		}, 800 );
	} );
	$( ".sidebar__nav ul" ).append( liElement );
} );

function addColorWhiteToSidebarNavList() {
	var sidebarNavList = $( ".sidebar__nav--list" );
	var sectionGreen = $( ".section-green" );

	var sidebarNavListTop = sidebarNavList.offset().top;
	var sidebarNavListBottom = sidebarNavListTop + sidebarNavList.height();

	var colorWhiteAdded = false; 

	sectionGreen.each( function() {
		var section = $( this );
		var sectionTop = section.offset().top;
		var sectionBottom = sectionTop + section.height();

		if ( sidebarNavListBottom >= sectionTop && sidebarNavListTop <= sectionBottom ) {
			sidebarNavList.addClass( "color-white" );
			colorWhiteAdded = true;
			return false;
		}
	} );

	if ( !colorWhiteAdded ) {
		sidebarNavList.removeClass( "color-white" );
	}
}

if ( $( ".sidebar__nav" ).length ) {
	$( window ).on( "scroll", addColorWhiteToSidebarNavList );
	addColorWhiteToSidebarNavList();

	function addActiveClassToSidebarNavList() {
		var sidebarNavList = $( ".sidebar__nav--list" );
		$( ".section" ).each( function( index ) {
			var section = $( this );
			var sidebarNavListTop = sidebarNavList.offset().top;
			var sidebarNavListBottom = sidebarNavListTop + sidebarNavList.height();
			var sectionTop = section.offset().top;
			var sectionBottom = sectionTop + section.height();
			if ( sidebarNavListBottom >= sectionTop && sidebarNavListTop <= sectionBottom ) {
				$( ".sidebar__nav li" ).removeClass( "active" );
				$( ".sidebar__nav li" ).eq( index ).addClass( "active" );
			}
		} );
	}

	$( window ).on( "scroll", addActiveClassToSidebarNavList );
	addActiveClassToSidebarNavList();
}

